import React from "react"
import { Container, Row, Col, Navbar, Nav, Image  } from "react-bootstrap"
import { FaInstagram, FaDiscord, FaTwitter } from 'react-icons/fa';
import LCCCLogo from "../../../images/LCCCLogo.png"

const styles = require("./provenance.module.scss")

type ProvenanceProps = {}

/**
 * Provenance page for LCCC.
 */
const Provenance: React.FC<ProvenanceProps> = () => {
  return (
    <>
      <Navbar collapseOnSelect variant="light" expand="lg" fixed="top" className={styles.navbar}>
        <Container>
          <Navbar.Brand>
            <a href="/">
              <Image className={styles.brandImage} src={LCCCLogo} />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className={`d-flex flex-column align-items-end justify-content-end ${styles.navContentContainer}`}>
              <Nav className={`d-flex align-items-center justify-content-center flex-row ${styles.socialIcons}`}>
                <a href="https://discord.gg/F6vy9vsAQe" target="_blank">
                  <FaDiscord className={styles.navSocialIcon} color={"black"} size={25} />
                </a>
                <a href="https://www.instagram.com/lightcultcryptoclub/" target="_blank">
                  <FaInstagram className={styles.navSocialIcon} color={"black"} size={25} />
                </a>
                <a href="https://twitter.com/LightCultCrypto" target="_blank">
                  <FaTwitter className={`${styles.navSocialIcon} ${styles.lastNavSocialIcon}`} color={"black"} size={25}/>
                </a>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className={styles.provenanceContainer}>
        <Row className={styles.formRow}>
          <Col>
            <h1>LIGHT CULT CRYPTO CLUB PROVENANCE RECORD</h1>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Provenance
