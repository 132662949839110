import React, { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import Web3 from "web3"
import { PageProps } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Provenance from "../../components/pages/provenance"
import LightCultCryptoClub from '../../../abis/development/LightCultCryptoClub.json'

const ProvenancePage: React.FC<PageProps> = pageProps => {
  // Pull web3 information - might be worth pulling this out so abstracted further from the page.
  // A HOC or putting it in Layout might be helpful.
  const [account, setAccount] = useState<string | null>(null)
  const [contract, setContract] = useState<any | null>(null)

  // web3
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")

  const getAndSetAccount = async () => {
    // Get customer account (wallet address) from meta mask
    const newAccounts = await web3.eth.getAccounts()
    const newAccount = newAccounts[0] as string
    setAccount(newAccount)
  }

  useEffect(() => {
    const setupWeb3Info = async () => {
      // Get contract from the network
      const networkId = await web3.eth.net.getId()

      const networkData = LightCultCryptoClub.networks[networkId]
      if (networkData) {
        const abi = LightCultCryptoClub.abi
        const contractAddress = networkData.address
        const contract = new web3.eth.Contract(abi, contractAddress)
        setContract(contract)
      }

      getAndSetAccount();
    }
    // Update the document title using the browser API
    setupWeb3Info()
  }, []);

  return (
    <Layout
      pageProps={pageProps}
      accountAddress={account}
      contract={contract}
      getAndSetAccount={getAndSetAccount}
      shouldShowNavbar={false}
      shouldShowFooter={true}
    >
      <SEO title="Provenance" />
      <Provenance />
    </Layout>
  )
}

export default ProvenancePage
